import React, { useState, useEffect } from 'react';
import GabzChar from '../img/gabz_character.png'; // Import character
import GabzCharIce from '../img/gabz_character_ice.png'; // Import character
import GabzCharFire from '../img/gabz_character_fire.png'; // Import character
import PixelIce from './PixelIce';
import PixelSword from './PixelSword';
import PixelFlame from './PixelFlame';
import TodoApp from './TodoApp'; // Ensure the path is correct

import Flame from './Flame';
import './MainLayout.css'; // Import the CSS file for the transition styles

const backgroundImageUrl = 'https://media.licdn.com/dms/image/v2/D5635AQFt-Adrk9458g/profile-framedphoto-shrink_200_200/profile-framedphoto-shrink_200_200/0/1725984261036?e=1731567600&v=beta&t=RaevE8XILH9LXUup4ZPejKIhe_Bgkvw7Ktdb1-PxaBY';

const About = () => {
  const images = [GabzChar, GabzCharIce, GabzCharFire];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsFading(false);
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleClickLeft = () => {
    alert('Left side clicked!');
  };

  return (
      <>

      {/*
    <div className="bg-white p-8 border-r border-gray-700">
      <h2>About Section</h2>
      <TodoApp />
    </div>
    */}


        {/* Professional Profile */}
        <div className="grid grid-cols-1 md:grid-cols-[8fr,4fr] gap-4 mb-8">
          {/* Text */}
          <div className="dark:bg-gray-800 p-8 border-r border-gray-700">
            <h2 className="text-2xl font-bold mb-4 text-white text-opacity-90">Professional Profile</h2>
            <ul className="text-white text-opacity-60 list-disc list-inside space-y-2">
              <li>Over <span className="font-bold">17 years</span> of experience in web development and e-learning management/development.</li>
              <li>Expert in tailoring new tools, websites, and apps to meet client needs, with <span className="font-bold">strong communication skills</span> to maintain good client relationships.</li>
              <li>Proficient in working with agile methodologies, Power Apps, and Azure DevOps.</li>
              <li>Expertise in creating and managing online reports to monitor <span className="font-bold">e-learning for over 10,000 employees</span>.</li>
              <li>Led <span className="font-bold">lean projects</span> to enhance human resources processes.</li>
              <li>Delivered courses, sharing knowledge and improving students' competencies to <span className="font-bold">make the web a better place</span>.</li>
              <li>Developed and managed my own business, guiding people, negotiating with clients, and handling marketing strategies.</li>
              <li>Contributed to the human development team, directly supporting employee growth.</li>
              <li>Languages: English, Spanish, and LSM (Lengua de Se&ntilde;as Mexicana - Mexican Sign Language).</li>
            </ul>
           
            <div className="m-12">

              <h3 className="text-white text-opacity-80 text-lg font-semibold mb-4">Languages</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-white m-12 text-opacity-80 text-sm">
              
                <div className="flex items-start">
                  <div className="flex items-start w-12 h-12 mr-2 opacity-50">
                    <PixelSword className="object-contain w-full h-full" />
                  </div>
                  <div className='font-bold'>
                    Spanish
                    <div className="text-xs text-opacity-50 font-normal">[Native]</div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex items-start w-12 h-12 mr-2 opacity-50">
                    <PixelIce className="object-contain w-full h-full" />
                  </div>
                  <div className='font-bold'>
                    English
                    <div className="text-xs text-opacity-50 font-normal">[Professional Working Proficiency]</div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex items-start w-12 h-12 mr-2 opacity-50">
                    <PixelFlame className="object-contain w-full h-full" />
                  </div>
                  <div className='font-bold'>           
                    LSM (Mexican Sign Language)
                    <div className="text-xs text-opacity-50 font-normal">[Conversational]</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Gabz LinkedIn */}
          <div className="dark:bg-gray-800 p-8 rounded-lg shadow-lg bg-primary relative">
            <h2 className="text-2xl font-bold mb-4 text-white text-opacity-90">
              <a
                href="https://www.linkedin.com/in/gabzreveur/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Gabriel Ch&aacute;vez 
                <span className="text-lg text-opacity-50 text-css pl-2 pt-2"> LinkedIn</span>
                <div
                  className={`circle  ${isHovered ? 'circle-hovered ' : ''}`}
                  style={{
                    backgroundImage: `url(${backgroundImageUrl})`,
                  }}
                ></div>
              </a>
            </h2>
            <div className="relative">
              {/* Character Image */}
              <img
                src={images[currentImageIndex]}
                alt="Sample"
                className={`rounded shadow-lg ${isFading ? 'fade-out' : 'fade-in'}`}
              />
            </div>
          </div>
        </div>
        {/* Gabz Skills */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {/* Languages */}
          <div className="dark:bg-gray-800 p-6 border-r border-gray-700 shadow-lg">
            <h2 className="text-white font-bold mb-4 text-opacity-90">PROGRAMMING LANGUAGES</h2>
            <ul className="text-white text-opacity-60 list-disc list-inside space-y-2">
              <li>Javascript 12+ yr <Flame /></li>
              <li>Html 12+ yr <Flame /></li>
              <li>CSS 12+ yr <Flame /></li>
              <li>PHP 6yr </li>
              <li>asp.net 1 yr</li>
              <li>MySQL 6 yr</li>
              <li>Power FX 3yr</li>
            </ul>
          </div>
          {/* Cms */}
          <div className="dark:bg-gray-800 p-6 border-r border-gray-700 shadow-lg">
            <h2 className="text-white font-bold mb-4 text-opacity-90">CMS</h2>
            <ul className="text-white text-opacity-60 list-disc list-inside space-y-2">
              <li>Sharepoint 3 yr <Flame /></li>
              <li>Power Platform 3 yr <Flame /></li>
              <li>Drupal 6 yr <Flame /></li>
              <li>Moodle 2 yr</li>
              <li>Wordpress 1 yr</li>
              <li>Magnolia 1 yr </li>
            </ul>
          </div>
          {/* Frameworks */}
          <div className="dark:bg-gray-800 p-6 border-r border-gray-700 shadow-lg">
            <h2 className="text-white font-bold mb-4 text-opacity-90">FRAMEWORKS</h2>
            <ul className="text-white text-opacity-60 list-disc list-inside space-y-2">
              <li>React 1 yr <Flame /></li>
              <li>Tailwind yr <Flame /></li>
              <li>Azure 2 yr <Flame /></li>
              <li>Jquery 5 yr</li>
              <li>Bootstrap 6 yr</li>
              <li>SPFx 1 yr</li>
              <li>Ajax 1 yr</li>
              <li>AWS 1 yr</li>
            </ul>
          </div>
          {/* Design & Tools */}
          <div className="dark:bg-gray-800 p-6 shadow-lg">
            <h2 className="text-lg font-bold mb-4 text-white text-opacity-90">DESIGN & TOOLS</h2>
            <ul className="text-white text-opacity-60 list-disc list-inside space-y-2">
              <li>Figma 1+ yr <Flame /></li>
              <li>Visual Studio Code 3+ yr <Flame /></li>
              <li>Atom 6+ yr</li>
              <li>Git 2 yr</li>
              <li>Adobe Creative Cloud (Illustrator, PhotoShop, InDesign) 5 yr</li>
              <li>Inkscape 2 yr</li>
              <li>Gimp 1 yr</li>
            </ul>
          </div>
        </div>
    </>
  );
};

export default About;
